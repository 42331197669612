import { ApolloQueryResult } from "@apollo/client";
import { AppQuery } from "generated/graphql";
import React, { useContext } from "react";

type AppViewerRefetchFunc = () => Promise<ApolloQueryResult<AppQuery>>;

interface AppViewerContextType {
  refetchAppViewer: AppViewerRefetchFunc;
}

export const AppViewerContext = React.createContext<
  AppViewerContextType | undefined
>(undefined);

export const useRefetchAppViewer = (): AppViewerRefetchFunc | undefined =>
  useContext(AppViewerContext)?.refetchAppViewer;
