import { LinkProps } from "next/link";
import Router, { NextRouter } from "next/router";

export type RouterLocation = Pick<LinkProps, "href" | "as">;

/**
 * router.(push|replace) の型定義が実態に即していないことに起因。
 * 具体的には第三引数が `{} | undefined` になっており不親切。
 */
interface NextRouterTransitionOptions {
  shallow?: boolean;
}
type RouterTransitionOptions = NextRouterTransitionOptions & {
  router?: NextRouter;
  scroll?: boolean;
};

const scrollToOrigin = () => {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
};

export const routerPush = async (
  { href, as }: RouterLocation,
  { router = Router, scroll, ...options }: RouterTransitionOptions = {}
): Promise<void> => {
  await router.push(href, as, options);
  if (scroll) scrollToOrigin();
};

export const routerReplace = async (
  { href, as }: RouterLocation,
  { router = Router, scroll, ...options }: RouterTransitionOptions = {}
): Promise<void> => {
  await router.replace(href, as, options);
  if (scroll) scrollToOrigin();
};

export function stripUserScreenNamePrefix(rawUserScreenName: string): string {
  if (!rawUserScreenName.startsWith("@")) {
    throw new Error("rawUserScreenName does't start with '@'");
  }
  return rawUserScreenName.slice(1);
}
