import { ApolloProvider, disableFragmentWarnings, gql } from "@apollo/client";
import { NormalizedCacheObject } from "@apollo/client/cache";
import { FlashMessageProvider } from "components/FlashMessageProvider";
import { ValveScript } from "components/ValveScripts";
import { ValveDisabledPaths } from "constants/valve";
import { PortfolioCategoryEditorProvider } from "contexts/portfolioCategoryEditor";
import { RouteHistoryProvider } from "contexts/routeHistory";
import { UseRedirectViewerFragmentDoc, useAppQuery } from "generated/graphql";
import { AppViewerContext } from "hooks/app";
import { useRedirectViewer } from "hooks/redirect";
import { init as initDayJS } from "lib/dayjs";
import "lib/polyfills";
import { defineSlots } from "lib/valve";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { IntlProvider } from "react-intl";
import Modal from "react-modal";
import { useApollo } from "../lib/apolloClient";
import "./app.scss";

// http://reactcommunity.org/react-modal/accessibility/#app-element
Modal.setAppElement("#__next");

// graphql-tag の Fragment 名重複の警告を消す
// 警告が出る原因ははっきりしていないが, 恐らく各 Component 内に定義された graphql-tag と, 自動生成されたものが重複とみなされている可能性がある
// その他に重複している箇所はなく, 動作に影響はなさそうなので警告を消す方針にする
// https://github.com/apollographql/graphql-tag#warnings
disableFragmentWarnings();

initDayJS();

gql`
  query App {
    viewer {
      ...UseRedirectViewer
    }
  }
  ${UseRedirectViewerFragmentDoc}
`;

export type AppPageProps = Omit<AppProps<PageProps>, "pageProps"> & {
  pageProps: PageProps;
  err: unknown;
};

interface PageProps {
  initialApolloState: NormalizedCacheObject | null;
}

const App: NextPage<AppPageProps> = (props) => {
  const { Component, pageProps } = props;

  const client = useApollo(pageProps.initialApolloState);
  const appQuery = useAppQuery({ client, fetchPolicy: "network-only" });
  useRedirectViewer(appQuery.data?.viewer);

  const appViewerContextValue = useMemo(
    () => ({ refetchAppViewer: appQuery.refetch }),
    [appQuery.refetch]
  );

  // トップレベルの例外を capture するための workaround。
  // _error の render 時に props として渡るようにしている。
  // see: https://github.com/zeit/next.js/issues/8592
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };

  // Valve の設定
  const { router } = props;
  // 一部のページでは Valve を無効化したいのでチェックする
  // FIXME: SPA 遷移時に Valve の有効状態が反映されないので注意
  const valveEnabled = useMemo(
    () => !ValveDisabledPaths.includes(router.pathname),
    [router.pathname]
  );
  // Valve のスロット設定。Valve が有効なら一度だけ設定する。
  useEffect(() => {
    if (!valveEnabled) return;
    defineSlots();
  }, [valveEnabled]);

  return (
    <>
      <Head>{valveEnabled && <ValveScript />}</Head>
      <ApolloProvider {...{ client }}>
        <AppViewerContext.Provider value={appViewerContextValue}>
          <DndProvider options={HTML5toTouch}>
            <IntlProvider locale={"en"}>
              <FlashMessageProvider>
                <RouteHistoryProvider>
                  <PortfolioCategoryEditorProvider>
                    <Component {...modifiedPageProps} />
                  </PortfolioCategoryEditorProvider>
                </RouteHistoryProvider>
              </FlashMessageProvider>
            </IntlProvider>
          </DndProvider>
        </AppViewerContext.Provider>
      </ApolloProvider>
    </>
  );
};

export default App;
