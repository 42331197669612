/** 広告スロットのDOMに指定するID */
export type SlotID = (typeof slotIds)[number];
const slotIds = [
  "valve-ad-viewer-pc-top",
  "valve-ad-viewer-pc-bottom",
  "valve-ad-viewer-sp",
] as const;

/** スロットIDと広告ユニットIDのマップ */
export const slotIdToUnit: Record<SlotID, string> = {
  "valve-ad-viewer-pc-top": "/4374287/mangano_pc_e_1_3025_0_no",
  "valve-ad-viewer-pc-bottom": "/4374287/mangano_pc_e_2_3025_0_no",
  "valve-ad-viewer-sp": "/4374287/mangano_sp_e_1_3025_0_no",
} as const;

/** window.valve の nullable を外したもの */
type Valve = Required<Window>["valve"];

/** window から valve を取得する。valveがundefinedなら、空の配列を代入した上で返す。 */
function getValve(): Valve {
  return (window.valve ||= []);
}

/**
 * 広告スロットの定義を行う。
 *
 * 広告スロットはただ一度だけ定義すればよいため、アプリケーションの生存期間中、この関数を何度も実行するべきではない。
 */
export function defineSlots(): void {
  getValve().push(async (v) => {
    await v.resetAllStates();

    v.config({
      service: "mangano",
      content: { result: "ng" },
    });

    // スロット定義
    for (const [slotId, unit] of Object.entries(slotIdToUnit)) {
      v.defineSlot({
        unit,
        slotId,
        sizes: [[300, 250]],
        lazy: true,
      });
    }

    // スロット定義完了をValveに通知
    v.sealSlots();
  });
}

/** 広告スロットに広告を表示する。 */
export function displaySlot(slotId: SlotID): void {
  getValve().push((v) => v.displaySlot(slotId));
}
