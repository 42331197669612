import { gql } from "@apollo/client";
import { UseRedirectViewerFragment } from "generated/graphql";
import { rr } from "lib/reverseRouter";
import { routerReplace } from "lib/router";
import { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { UrlObject } from "url";

type RedirectCondition = (viewer: UseRedirectViewerFragment) => boolean;

const isAnonymousViewer: RedirectCondition = (viewer) => {
  return (
    viewer.__typename === "GuestViewer" && viewer.authProviders.length === 0
  );
};
const isUnregisteredViewer: RedirectCondition = (viewer) => {
  return (
    viewer.__typename === "GuestViewer" && viewer.authProviders.length !== 0
  );
};

const isRegisteredViewer: RedirectCondition = (viewer) => {
  return viewer.__typename === "RegisteredViewer";
};

const isRegisteredNonEmailViewer: RedirectCondition = (viewer) => {
  return isRegisteredViewer(viewer) && !viewer.authProviders.includes("EMAIL");
};

type RedirectTo = (currentPathName: string, toHref?: string) => LinkProps;

type RedirectRule = {
  from: RegExp;
  to: RedirectTo;
  condition: RedirectCondition;
};

type Url = UrlObject | string;

function pathname(url: Url): string {
  if (typeof url === "string") {
    return url;
  }
  return url.pathname ?? "/";
}

// 匿名ユーザーのリダイレクトルール
const anonymousViewerRules: RedirectRule[] = [
  // 閲覧履歴
  {
    from: new RegExp(`^${pathname(rr.my.readingHistories.index().href)}.*`),
    to: () => rr.readingHistories.index(),
    condition: isAnonymousViewer,
  },
  // ワークスペース
  {
    from: new RegExp(`^${pathname(rr.workspace.index().href)}.*`),
    to: (to) => rr.signin.index({ to }),
    condition: isAnonymousViewer,
  },
  // マイページ
  {
    from: new RegExp(`^${pathname(rr.my.index().href)}.*`),
    to: (to) => rr.signin.index({ to }),
    condition: isAnonymousViewer,
  },
  // プロフィール登録ページ
  {
    from: new RegExp(`^${pathname(rr.signup.profile.index({}).href)}/?$`),
    to: () => rr.index(),
    condition: isAnonymousViewer,
  },
  // OIDC 認証リクエストページ
  {
    from: new RegExp(`^${pathname(rr.oauth2.authorize.index().href)}.*`),
    to: (to) => rr.signin.index({ to }),
    condition: isAnonymousViewer,
  },
];

// 未登録ユーザーのリダイレクトルール
// 未登録ユーザーがユーザー登録に必要なページにアクセスした際に、プロフィール設定ページにリダイレクトする
const unregisteredViewerRules: RedirectRule[] = [
  // 閲覧履歴
  {
    from: new RegExp(`^${pathname(rr.my.readingHistories.index().href)}.*`),
    to: () => rr.readingHistories.index(),
    condition: isUnregisteredViewer,
  },
  // ワークスペース
  {
    from: new RegExp(`^${pathname(rr.workspace.index().href)}.*`),
    to: (to) => rr.signup.profile.index({ to }),
    condition: isUnregisteredViewer,
  },
  // マイページ
  {
    from: new RegExp(`^${pathname(rr.my.index().href)}.*`),
    to: (to) => rr.signup.profile.index({ to }),
    condition: isUnregisteredViewer,
  },
  // ログインページ
  {
    from: new RegExp(`^${pathname(rr.signin.index({}).href)}.*`),
    to: (_, toHref) => rr.signup.profile.index({ to: toHref }),
    condition: isUnregisteredViewer,
  },
  // 会員登録ページ
  {
    from: new RegExp(`^${pathname(rr.signup.index({}).href)}/?$`),
    to: (_, toHref) => rr.signup.profile.index({ to: toHref }),
    condition: isUnregisteredViewer,
  },
  // メールアドレス確認ページ
  {
    from: new RegExp(`^${pathname(rr.signup.verifyEmail.index().href)}/?$`),
    to: (_, toHref) => rr.signup.profile.index({ to: toHref }),
    condition: isUnregisteredViewer,
  },
];

// 登録済みではあるが、メールアドレスが登録されていないユーザーのリダイレクトルール
const registeredNonEmailViewerRules: RedirectRule[] = [
  // ワークスペース
  {
    from: new RegExp(`^${pathname(rr.workspace.index().href)}.*`),
    to: () => rr.my.settings.account.index(),
    condition: isRegisteredNonEmailViewer,
  },
  // ユーザー登録完了後、リダイレクト先またはマイページへ飛ばす
  {
    from: new RegExp(`^${pathname(rr.signup.profile.index({}).href)}/?$`),
    to: (_, toHref) => (toHref ? { href: toHref } : rr.my.index()),
    condition: isRegisteredNonEmailViewer,
  },
  // メールアドレス変更ページ
  {
    from: new RegExp(
      `^${pathname(rr.my.settings.account.changeEmail.index().href)}/?$`
    ),
    to: () => rr.index(),
    condition: isRegisteredNonEmailViewer,
  },
  // メールアドレス変更確認ページ
  {
    from: new RegExp(
      `^${pathname(rr.my.settings.account.verifyChangeEmail.index().href)}/?$`
    ),
    to: () => rr.index(),
    condition: isRegisteredNonEmailViewer,
  },
];

// 登録済みユーザーのリダイレクトルール
const registeredViewerRules: RedirectRule[] = [
  // 閲覧履歴ページ
  {
    from: new RegExp(`^${pathname(rr.readingHistories.index().href)}/?$`),
    to: () => rr.my.readingHistories.index(),
    condition: isRegisteredViewer,
  },
  // ログインページ
  {
    from: new RegExp(`^${pathname(rr.signin.index({}).href)}.*`),
    to: (_, toHref) => (toHref ? { href: toHref } : rr.index()),
    condition: (v) => isRegisteredViewer(v),
  },
  // メールアドレス確認ページ
  {
    from: new RegExp(`^${pathname(rr.signup.verifyEmail.index().href)}/?$`),
    to: () => rr.index(),
    condition: (v) => isRegisteredViewer(v),
  },
  // 会員登録ページ
  {
    from: new RegExp(`^${pathname(rr.signup.index({}).href)}/?$`),
    to: (_, toHref) => (toHref ? { href: toHref } : rr.index()),
    condition: (v) => isRegisteredViewer(v),
  },
  // ユーザー登録完了後、リダイレクト先またはワークスペースへ飛ばす
  {
    from: new RegExp(`^${pathname(rr.signup.profile.index({}).href)}/?$`),
    to: (_, toHref) => (toHref ? { href: toHref } : rr.workspace.index()),
    condition: (v) => isRegisteredViewer(v),
  },
];

const rules: RedirectRule[] = [
  ...anonymousViewerRules,
  ...unregisteredViewerRules,
  // RegisteredNonEmailViewer は, RegisteredViewer でもあるので RegisteredViewer より先にルールが適用されるように上に定義する
  ...registeredNonEmailViewerRules,
  ...registeredViewerRules,
];

gql`
  fragment UseRedirectViewer on Viewer {
    authProviders
  }
`;

export function useRedirectViewer(
  viewer: UseRedirectViewerFragment | undefined
): void {
  const router = useRouter();
  const { asPath, pathname, query } = router;
  const toHref = query.to as string;

  if (!viewer) {
    return;
  }

  for (const rule of rules) {
    if (rule.from.exec(pathname) && rule.condition(viewer)) {
      routerReplace(rule.to(asPath, toHref));
      return;
    }
  }
}
