import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

const RouteHistoryContext = createContext<string[]>([]);

export const RouteHistoryProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const router = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    // 同じrouteは二重に記録しない
    if (history[0] === router.route) return;

    const onRouteChangeComplete = () => {
      // 無限に覚えておく意味はないので直近10件だけ覚えておく
      setHistory([router.route, ...history].slice(0, 10));
    };
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [history, router.events, router.route]);

  return (
    <RouteHistoryContext.Provider value={history}>
      {children}
    </RouteHistoryContext.Provider>
  );
};

export function useRouteHistory(): string[] {
  return useContext(RouteHistoryContext);
}
