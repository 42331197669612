/**
 * Middleware からAPIを呼び出す際に利用するトークン
 * XXX: https://github.com/hatena/mangano/tree/main/pkg/auth/http.go#L19 のMiddlewareAuthorizationProductionToken/MiddlewareAuthorizationDevelopmentToken と同じ値を設定すること
 */
export const MiddlewareAuthorizationToken = {
  PRODUCTION:
    "hKybrI4Xxqw/zFE7WyIZ6ekAmuDxKbF5w9QD9o0asc+aWllq5aH/kEOSMLMLbrsmkSBAdNuzOqD2XB66DcxI0A==",
  DEVELOPMENT:
    "TPlI7TYJbicdEPKIXPpMXnquS5S7fKNuM26B9+DIDYpEvPxwFJIBqwjfjTccd1y3YTPEhyhwy4u6alC/LjWHnA==",
} as const;
