import { LinkProps } from "next/link";
import { ParsedUrlQueryInput } from "querystring";
import url, { UrlObject } from "url";

type MixedRecord<
  KRequired extends string,
  KOptional extends string,
  T,
> = Record<KRequired, T> & Partial<Record<KOptional, T>>;

interface ApplyQueryOptions {
  omitEmpty?: boolean;
}

export const applyQuery = (
  originalUrl: string | UrlObject,
  query: ParsedUrlQueryInput,
  { omitEmpty = true }: ApplyQueryOptions = {}
): UrlObject => ({
  ...(typeof originalUrl === "string" ? url.parse(originalUrl) : originalUrl),
  query: Object.entries(query).reduce(
    (acc, [key, value]) =>
      !omitEmpty || value ? { ...acc, [key]: value } : acc,
    {} as ParsedUrlQueryInput
  ),
});

interface DynamicChain<KReq extends string, KOpt extends string> {
  (params: MixedRecord<KReq, KOpt, string>): LinkProps;
  withQuery<KQuery extends string>(
    keys: KQuery[],
    options?: ApplyQueryOptions
  ): DynamicChain<KReq, KOpt | KQuery>;
}

export const withQuery =
  <
    KReq extends string = never,
    KOpt extends string = never,
    KQuery extends string = never,
  >(
    fn: (params: MixedRecord<KReq, KOpt, string>) => LinkProps,
    keys: KQuery[],
    options?: ApplyQueryOptions
  ): ((params: MixedRecord<KReq, KOpt | KQuery, string>) => LinkProps) =>
  (params) => {
    const { href } = fn(params);
    const query = keys.reduce(
      (acc, key) => ({ ...acc, [key]: params[key] }),
      {} as Record<string, string>
    );

    return {
      href: applyQuery(href, query, options),
    };
  };

const dynamicChain = <KReq extends string = never, KOpt extends string = never>(
  fn: (params: MixedRecord<KReq, KOpt, string>) => LinkProps
): DynamicChain<KReq, KOpt> => {
  const chained: DynamicChain<KReq, KOpt> = (params) => fn(params);
  chained.withQuery = <KQuery extends string = never>(
    keys: KQuery[],
    options?: ApplyQueryOptions
  ) => dynamicChain(withQuery(fn, keys, options));

  return chained;
};

export const dynamicPathGenerator =
  <K extends string = never>(
    strings: TemplateStringsArray,
    ...keys: readonly K[]
  ): ((params: Record<K, string>) => string) =>
  (params) =>
    strings.reduce(
      (prev, cur, i) => prev + encodeURIComponent(params[keys[i - 1]]) + cur
    );

export const dynamicPath = <K extends string = never>(
  strings: TemplateStringsArray,
  ...keys: readonly K[]
): DynamicChain<K, never> => {
  const generatePath = dynamicPathGenerator(strings, ...keys);

  return dynamicChain((params) => ({
    href: generatePath(params),
  }));
};

const staticPath =
  (href: string): (() => LinkProps) =>
  () => ({
    href,
  });

// 命名規則
//
// 基本となるルール:
// - 単純なセグメントはそのまま (e.g. /works -> works)
// - path parameter はその対象を表す単数形の名詞に (e.g. /works/[workId] の [workId] -> work)
// - パスの終端は index で表現
//
// 例:
// - /works は rr.works.index
// - /works/[workId] は rr.works.work.index
// - /works/[workId]/foo は rr.works.work.foo.index

export const rr = {
  index: staticPath("/"),
  site: {
    index: dynamicPath`/@${"userScreenName"}`,
    purchaseFailed: {
      index: dynamicPath`/@${"userScreenName"}?purchase_failed`,
    },
    books: {
      index: dynamicPath`/@${"userScreenName"}/books`,
    },
    illustrations: {
      index: dynamicPath`/@${"userScreenName"}/illustrations`,
    },
    manga: {
      index: dynamicPath`/@${"userScreenName"}/manga`,
    },
    profile: {
      index: dynamicPath`/@${"userScreenName"}/profile`,
    },
    /*
    `src/pages/[userScreenName]/mangafolio/index.tsx`
    `src/pages/[userScreenName]/mangafolio/external_works/[externalWorkId].tsx`
    `src/pages/[userScreenName]/mangafolio/mangano_works/[manganoWorkId].tsx`
    `src/pages/[userScreenName]/mangafolio/about.tsx`
    */
    mangafolio: {
      index: dynamicPath`/@${"userScreenName"}/mangafolio`,
      external_works: {
        external_work: {
          index: dynamicPath`/@${"userScreenName"}/mangafolio/external_works/${"externalWorkId"}`,
        },
      },
      mangano_works: {
        mangano_work: {
          index: dynamicPath`/@${"userScreenName"}/mangafolio/mangano_works/${"manganoWorkId"}`,
        },
      },
    },
  },
  episodes: {
    episode: {
      index: dynamicPath`/episodes/${"episodeId"}`,
      purchaseSucceeded: {
        index: dynamicPath`/episodes/${"episodeId"}?purchase_succeeded`,
      },
      purchaseFailed: {
        index: dynamicPath`/episodes/${"episodeId"}?purchase_failed`,
      },
    },
  },
  illustrations: {
    illustration: {
      index: dynamicPath`/illustrations/${"illustrationId"}`,
      preview: {
        index: (params: {
          illustrationId: string;
          index?: number;
        }): LinkProps => {
          const index = params.index ?? 0;
          const path = `/illustrations/${params.illustrationId}?preview`;
          return {
            href: `${path}&index=${index}`,
            as: path,
          };
        },
      },
    },
  },
  handleMailAction: {
    index: staticPath("/handle_mail_action"),
  },
  handlePurchaseError: {
    index: staticPath("/handle_purchase_error"),
  },
  my: {
    index: staticPath("/my"),
    bookmarks: {
      index: staticPath("/my/bookmarks"),
    },
    readingHistories: {
      index: staticPath("/my/reading_histories"),
    },
    purchased: {
      index: staticPath("/my/purchased"),
    },
    settings: {
      index: staticPath("/my/settings"),
      account: {
        index: staticPath("/my/settings/account"),
        addEmailAuth: {
          index: staticPath("/my/settings/account/add_email_auth"),
        },
        addTwitterAuth: {
          index: staticPath("/my/settings/account/add_twitter_auth"),
        },
        changeEmail: {
          index: staticPath("/my/settings/account/change_email"),
        },
        changePassword: {
          index: staticPath("/my/settings/account/change_password"),
        },
        delete: {
          index: staticPath("/my/settings/account/delete"),
        },
        verifyAddEmailAuth: {
          index: staticPath("/my/settings/account/verify_add_email_auth"),
        },
        verifyChangeEmail: {
          index: staticPath("/my/settings/account/verify_change_email"),
        },
        comicCopilotConnect: {
          index: staticPath("/my/settings/account/comic_copilot_connect"),
        },
      },
      profile: {
        index: staticPath("/my/settings/profile"),
      },
      purchaseHistory: {
        index: staticPath("/my/settings/purchase_history"),
      },
      notification: {
        index: staticPath("/my/settings/notification"),
      },
    },
  },
  resetPassword: {
    index: staticPath("/reset_password"),
  },
  signin: {
    index: dynamicPath`/signin`.withQuery(["to"]),
    twitter: {
      index: dynamicPath`/signin/twitter`.withQuery(["to"]),
    },
  },
  signup: {
    index: dynamicPath`/signup`.withQuery(["to"]),
    profile: {
      index: dynamicPath`/signup/profile`.withQuery([
        "to",
        "screenName",
        "displayName",
        "selfIntroduction",
      ]),
    },
    verifyEmail: {
      index: staticPath("/signup/verify_email"),
    },
  },
  verifyRecoverEmail: {
    index: staticPath("/verify_recover_email"),
  },
  verifyResetPassword: {
    index: staticPath("/verify_reset_password"),
  },
  works: {
    index: dynamicPath`/works/${"workId"}`.withQuery([
      "utm_source",
      "utm_content",
      "utm_campaign",
    ]),
  },
  workspace: {
    index: staticPath("/workspace"),
    fans: {
      index: staticPath("/workspace/fans"),
    },
    site: {
      index: staticPath("/workspace/site"),
      about: {
        index: staticPath("/workspace/site/about"),
      },
      edit: {
        index: staticPath("/workspace/site/edit"),
      },
      settings: {
        index: staticPath("/workspace/site/settings"),
      },
      ad_tags: {
        index: staticPath("/workspace/site/ad_tags"),
      },
    },
    accesses: {
      index: staticPath("/workspace/accesses"),
      works: {
        index: dynamicPath`/workspace/accesses/works/${"workId"}`,
      },
    },
    comments: {
      index: dynamicPath`/workspace/comments`.withQuery(["tab"]),
    },
    works: {
      index: staticPath("/workspace/works"),
    },
    mangafolio: {
      index: staticPath("/workspace/mangafolio"),
      edit: {
        index: staticPath("/workspace/mangafolio/edit"),
        categories: {
          new: {
            index: staticPath("/workspace/mangafolio/edit/categories/new"),
            external_works: {
              index: staticPath(
                "/workspace/mangafolio/edit/categories/new/external_works"
              ),
              new: {
                index: staticPath(
                  "/workspace/mangafolio/edit/categories/new/external_works/new"
                ),
              },
              edit: {
                index: dynamicPath`/workspace/mangafolio/edit/categories/new/external_works/${"externalWorkId"}`,
              },
            },
            mangano_works: {
              index: staticPath(
                "/workspace/mangafolio/edit/categories/new/mangano_works"
              ),
              edit: {
                index: dynamicPath`/workspace/mangafolio/edit/categories/new/mangano_works/${"manganoWorkId"}`,
              },
            },
          },
          edit: {
            index: dynamicPath`/workspace/mangafolio/edit/categories/${"categoryId"}`,
            external_works: {
              index: dynamicPath`/workspace/mangafolio/edit/categories/${"categoryId"}/external_works`,
              new: {
                index: dynamicPath`/workspace/mangafolio/edit/categories/${"categoryId"}/external_works/new`,
              },
              edit: {
                index: dynamicPath`/workspace/mangafolio/edit/categories/${"categoryId"}/external_works/${"externalWorkId"}`,
              },
            },
            mangano_works: {
              index: dynamicPath`/workspace/mangafolio/edit/categories/${"categoryId"}/mangano_works`,
              edit: {
                index: dynamicPath`/workspace/mangafolio/edit/categories/${"categoryId"}/mangano_works/${"manganoWorkId"}`,
              },
            },
          },
        },
        works: {
          index: staticPath("/workspace/mangafolio/edit/works"),
          external_works: {
            edit: {
              index: dynamicPath`/workspace/mangafolio/edit/works/external_works/${"externalWorkId"}`,
            },
          },
          mangano_works: {
            edit: {
              index: dynamicPath`/workspace/mangafolio/edit/works/mangano_works/${"manganoWorkId"}`,
            },
          },
        },
      },
    },
    manga: {
      index: staticPath("/workspace/manga"),
      new: {
        index: staticPath("/workspace/manga/new"),
      },
      work: {
        index: dynamicPath`/workspace/manga/${"workId"}`,
        edit: {
          index: dynamicPath`/workspace/manga/${"workId"}/edit`,
        },
        episodes: {
          episode: {
            edit: {
              index: dynamicPath`/workspace/manga/${"workId"}/episodes/${"episodeId"}/edit`,
            },
          },
          new: {
            index: dynamicPath`/workspace/manga/${"workId"}/episodes/new`,
          },
        },
      },
    },
    illustrations: {
      index: staticPath("/workspace/illustrations"),
      new: {
        index: staticPath("/workspace/illustrations/new"),
      },
      edit: {
        index: dynamicPath`/workspace/illustrations/${"illustrationId"}`,
      },
    },
    sales: {
      summary: {
        index: staticPath("/workspace/sales/summary"),
      },
      monthly: {
        year: {
          month: {
            index: dynamicPath`/workspace/sales/monthly/${"year"}/${"month"}`,
            works: {
              work: {
                index: dynamicPath`/workspace/sales/monthly/${"year"}/${"month"}/works/${"monthlyWorkSalesSummaryId"}`,
              },
            },
          },
        },
      },
      withdrawal: {
        index: staticPath("/workspace/sales/withdrawal"),
        new: {
          index: staticPath("/workspace/sales/withdrawal/new"),
          gift: {
            index: staticPath("/workspace/sales/withdrawal/new/gift"),
          },
        },
        history: {
          index: staticPath("/workspace/sales/withdrawal/history"),
        },
        bank: {
          index: staticPath("/workspace/sales/withdrawal/bank"),
          register: {
            index: staticPath("/workspace/sales/withdrawal/bank/register"),
          },
          edit: {
            index: staticPath("/workspace/sales/withdrawal/bank/edit"),
          },
        },
      },
    },
  },
  readingHistories: {
    index: staticPath("/reading_histories"),
  },
  creator: {
    index: staticPath("/creator"),
  },
  search: {
    index: dynamicPath`/search/`.withQuery(["q", "o"]),
  },
  rankings: {
    monthly: {
      index: staticPath("/rankings/monthly"),
    },
    weekly: {
      index: staticPath("/rankings/weekly"),
    },
    daily: {
      index: staticPath("/rankings/daily"),
    },
    total: {
      index: staticPath("/rankings/total"),
    },
  },
  newWorks: {
    index: staticPath("/new_works"),
  },
  mangafolio: {
    index: staticPath("/mangafolio"),
  },
  tags: {
    index: dynamicPath`/tags/${"tagTitle"}`,
  },
  notifications: {
    index: staticPath("/notifications"),
  },
  // FIXME: manganoAward1stに修正したい
  manganoAward2023: {
    index: staticPath("/mangano_award_1st"),
    result: {
      index: staticPath("/mangano_award_1st/result"),
    },
  },
  manganoAward2nd: {
    index: staticPath("/mangano_award_2nd"),
  },
  oauth2: {
    authorize: {
      index: staticPath("/oauth2/authorize"),
    },
  },
};
