import dayjs from "dayjs";
import "dayjs/locale/ja";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

/**
 * day.js の初期設定を行う。
 *
 * Asia/Tokyoタイムゾーンをデフォルトで有効化し、ロケールを日本語に設定する。
 * その他、必要なプラグインを有効化する。
 **/
export function init(): void {
  // https://day.js.org/docs/en/plugin/is-same-or-after
  dayjs.extend(isSameOrAfter);
  // https://day.js.org/docs/en/plugin/is-same-or-before
  dayjs.extend(isSameOrBefore);

  // https://day.js.org/docs/en/timezone/timezone
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // https://day.js.org/docs/en/plugin/relative-time
  dayjs.extend(relativeTime);

  // https://day.js.org/docs/en/timezone/set-default-timezone
  dayjs.tz.setDefault("Asia/Tokyo");

  // https://day.js.org/docs/en/i18n/changing-locale
  dayjs.locale("ja");
}
