import classNames from "classnames";
import {
  FlashMessagePosition,
  FlashMessageType,
} from "components/FlashMessageProvider";
import styles from "./FlashMessage.module.scss";

interface FlashMessageProps {
  message: string;
  type: FlashMessageType;
  position?: FlashMessagePosition;
}

export const FlashMessage: React.FC<FlashMessageProps> = ({
  message,
  type,
  position,
}) => {
  return (
    <div
      className={classNames({
        [styles.flash_message_success]: type === "success",
        [styles.flash_message_error]: type === "error",
        [styles.flash_message_info]: type === "info",
        [styles.position_bottom]: position === "bottom",
        [styles.position_bottom_with_navi]: position === "bottom_with_navi",
      })}
    >
      {message}
    </div>
  );
};
