import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfigMap = {
  develop: {
    apiKey: "AIzaSyBfK9HEz-bMiQX3suPqJf6hcSsPYZaIGWQ",
    authDomain: "newjump-36a10.firebaseapp.com",
    databaseURL: "https://newjump-36a10.firebaseio.com",
    projectId: "newjump-36a10",
    storageBucket: "newjump-36a10.appspot.com",
    messagingSenderId: "99341862226",
    appId: "1:99341862226:web:59e380e30bd4c0aa75e68b",
  },
  preview: {
    apiKey: "AIzaSyC5tTWuRswOg3tl1xZeF-mfIxBS4fVNcyE",
    authDomain: "mangano-deploy-preview.firebaseapp.com",
    projectId: "mangano-deploy-preview",
    storageBucket: "mangano-deploy-preview.appspot.com",
    messagingSenderId: "1075336670559",
    appId: "1:1075336670559:web:ae684bda358257c7c0daea",
  },
  staging: {
    apiKey: "AIzaSyDrI760YYRHT9OaRvLWeoxcqdu4xu3y5pk",
    authDomain: "mangano-staging.firebaseapp.com",
    databaseURL: "https://mangano-staging.firebaseio.com",
    projectId: "mangano-staging",
    storageBucket: "mangano-staging.appspot.com",
    messagingSenderId: "387297599640",
    appId: "1:387297599640:web:fdc8d519cb93cb15a4b0ed",
  },
  production: {
    apiKey: "AIzaSyASnOvvLWrECQKNRI0R_82droxO1QMd4O8",
    authDomain: "mangano-production.firebaseapp.com",
    databaseURL: "https://mangano-production.firebaseio.com",
    projectId: "mangano-production",
    storageBucket: "mangano-production.appspot.com",
    messagingSenderId: "621856509225",
    appId: "1:621856509225:web:cee3a29ff2c2d74db03b38",
  },
};

type FirebaseEnv = keyof typeof firebaseConfigMap;

const firebaseConfig =
  firebaseConfigMap[process.env.NEXT_PUBLIC_FIREBASE_ENV as FirebaseEnv];

export function initOrGetApp(): firebase.app.App {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  return firebase.app();
}

// ユーザーがサインイン済みだったらそのユーザーを返す。サインインしていない場合は匿名ユーザーとしてサインアップしてそのユーザーを返す。
export async function getUserOrSignInAnonymously(): Promise<firebase.User> {
  const app = initOrGetApp();
  const auth = app.auth();

  // ユーザーがすでに resolve されていれば場合はそれを返す
  if (auth.currentUser) return auth.currentUser;

  // ユーザーが resolve されていなければされるまで待つ
  const user = await new Promise<firebase.User | null>((resolve, reject) => {
    auth.onAuthStateChanged(resolve, reject);
  });

  // ユーザーが null でなかった（セッションを復元できた）場合はトークンをリフレッシュし、復元したユーザーを返す
  if (user) {
    await user.getIdToken(true);
    return user;
  }

  // ユーザーが取得できなかった（一度もサインインしたことがなかった）場合は匿名ユーザーとしてサインインし、そのユーザーを返す
  const userCredential = await auth.signInAnonymously();
  if (userCredential.user) return userCredential.user;

  throw new Error("Sign in failed");
}

export interface AuthContext {
  uid: string;
  idToken: string;
}

export async function getAuthContext(
  forceRefreshIdToken?: boolean | undefined
): Promise<AuthContext> {
  const user = await getUserOrSignInAnonymously();
  const idToken = await user.getIdToken(forceRefreshIdToken);
  return { uid: user.uid, idToken };
}

// hatenaのメアドを持つユーザかどうかを返す
export function isHatenaEmailUser(email: string | null): boolean {
  if (!email) return false;
  return email.endsWith("@hatena.ne.jp");
}

// ポートフォリオ機能が有効化されているユーザかどうかを返す
// hatenaのメアドを使っている場合も有効
export function isPortfolioEnabledForUser(user: firebase.User): boolean {
  if (isHatenaEmailUser(user.email)) return true;
  const portfolioEnabledUIDs = [
    // mangano-development
    "CzggMebFSyXW9GhrsiA31cX0grh1", // @stenyan
    // mangano-production ポートフォリオ機能を触る関係者各位向けに解放
    "z1YwqJ0gRaMgEbSnLqGrP3YKhfn2", // @yudai1124
    "fqHLlfGjoteqAn9yoPEfyam7tWu1", // @nc-ima
    "fKfB7kAwhLdZU5zDLZgY0u5AGmv2", // @myyt
  ];
  return portfolioEnabledUIDs.includes(user.uid);
}
