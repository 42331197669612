import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FlashMessage } from "./elements/FlashMessage";

const flashMessageTypes = ["success", "info", "error"] as const;
export type FlashMessageType = (typeof flashMessageTypes)[number];

const flashMessagePositions = ["top", "bottom", "bottom_with_navi"] as const;
export type FlashMessagePosition = (typeof flashMessagePositions)[number];

export type FlashMessage = {
  body: string | null;
  type: FlashMessageType;
  position?: FlashMessagePosition;
};

export type FlashMessageContextProps = {
  readonly flashMessage: FlashMessage;
  readonly setFlashMessage: (message: FlashMessage) => void;
  readonly clearFlashMessage: () => void;
};

export const FlashMessageContext = createContext<FlashMessageContextProps>({
  flashMessage: { body: null, type: "info", position: "top" },
  setFlashMessage: () => undefined,
  clearFlashMessage: () => undefined,
});

type FlashMessageProviderProps = {
  children?: ReactNode;
};

export const FlashMessageProvider: React.FC<FlashMessageProviderProps> = ({
  children,
}) => {
  const [{ body, type, position }, setFlashMessageState] =
    useState<FlashMessage>({
      body: null,
      type: "info",
      position: "top",
    });
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const clearFlashMessage = useCallback(() => {
    setFlashMessageState({ body: null, type: "info", position: "top" });
  }, []);

  const setFlashMessage = useCallback(
    (message: FlashMessage) => {
      if (timerId) {
        clearTimeout(timerId);
      }

      setFlashMessageState(message);

      setTimerId(setTimeout(clearFlashMessage, 3000));
    },
    [clearFlashMessage, timerId]
  );

  const flashMessageContext = useMemo<FlashMessageContextProps>(() => {
    return {
      flashMessage: { body, type, position },
      setFlashMessage,
      clearFlashMessage,
    };
  }, [body, type, position, setFlashMessage, clearFlashMessage]);

  return (
    <FlashMessageContext.Provider value={flashMessageContext}>
      {body && <FlashMessage message={body} type={type} position={position} />}
      {children}
    </FlashMessageContext.Provider>
  );
};
