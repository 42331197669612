import { ApolloLink } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { ApolloClient } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import deepmerge from "deepmerge";
import { getAuthContext } from "lib/firebase";
import { useMemo } from "react";
import { apolloTypePolicies } from "./apolloTypePolicies";
import { MiddlewareAuthorizationToken } from "constants/middleware";

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext(async () => {
  const authContext = await getAuthContext();
  const { idToken } = authContext;
  // contextにidTokenが入ってたらそれを使う
  if (idToken) {
    return {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    };
  }
});

const authMiddlewareLink = setContext(async () => {
  // Next.js の Middleware からは Firebase Auth が使えないので、Middleware 用のトークンを使う
  const token =
    process.env.NEXT_PUBLIC_APP_ENV === "production"
      ? MiddlewareAuthorizationToken.PRODUCTION
      : MiddlewareAuthorizationToken.DEVELOPMENT;

  return {
    headers: {
      authorization: `Middleware ${token}`,
    },
  };
});

// SSR の場合は X-Is-SSR ヘッダを付与する
const isSSRLink = setContext(async (_operation, prevContext) => {
  if (typeof window === "undefined") {
    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        "X-Is-SSR": 1,
      },
    };
  }
});

function isPuppeteer() {
  try {
    // @ts-expect-error Property 'isPuppeteer' does not exist on type 'Window & typeof globalThis'.
    return window.isPuppeteer;
  } catch {
    return false;
  }
}

function getGraphqlEndpoint() {
  // ローカル環境で、NEXT_PUBLIC_LOCAL_OG_GRAPHQL_ENDPOINT が設定されていて、Puppeteer で動いている事を検知したら、NEXT_PUBLIC_LOCAL_OG_GRAPHQL_ENDPOINT を使う
  if (
    process.env.NEXT_PUBLIC_APP_ENV === "local" &&
    process.env.NEXT_PUBLIC_LOCAL_OG_GRAPHQL_ENDPOINT !== undefined &&
    isPuppeteer()
  ) {
    return process.env.NEXT_PUBLIC_LOCAL_OG_GRAPHQL_ENDPOINT;
  }

  // window が undefined なら SSR 環境なので SSR_GRAPHQL_ENDPOINT を使う
  if (typeof window === "undefined") {
    return process.env.SSR_GRAPHQL_ENDPOINT;
  }

  // それ以外は通常のブラウザなので NEXT_PUBLIC_GRAPHQL_ENDPOINT を使う
  return process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;
}

const graphqlEndpoint = getGraphqlEndpoint();

// GraphQL Endpoint のURLに対して何かする場合は、このLinkに処理を追加すること
const rewriteUriLink = setContext(async (operation) => {
  // operation name をクエリパラメーターに追加する
  return { uri: `${graphqlEndpoint}?opname=${operation.operationName}` };
});

const uploadLink = createUploadLink({
  fetch: async (input, init) => {
    // CI上で何故かSSGに失敗することがあるのでその調査のためにログを仕込む
    const res = await fetch(input, init);
    if (process.env.CI === "true" && res.status !== 200) {
      console.log(await res.text());
    }
    return res;
  },
  credentials:
    process.env.NEXT_PUBLIC_APP_ENV === "production" ? undefined : "include", // 本番以外は API 側の Cognito 認証を突破する必要があるので認証情報を含めるように
});

export function createInMemoryCache(): InMemoryCache {
  return new InMemoryCache({
    typePolicies: apolloTypePolicies,
    possibleTypes: {
      Viewer: ["GuestViewer", "RegisteredViewer"],
      Node: ["Work"],
      PortfolioWork: ["PortfolioWorkMangano", "PortfolioWorkExternal"],
    },
  });
}

export function createApolloClient(
  isMiddleware: boolean = false
): ApolloClient<NormalizedCacheObject> {
  // middleware から呼ばれる場合は firebase auth を呼びだせないので替わりに authMiddlewareLink を使う
  const links = isMiddleware
    ? [onErrorLink, isSSRLink, authMiddlewareLink, rewriteUriLink, uploadLink]
    : [onErrorLink, isSSRLink, authLink, rewriteUriLink, uploadLink];

  const link = ApolloLink.from(links);

  return new ApolloClient({
    cache: createInMemoryCache(),
    ssrMode: typeof window === "undefined",
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "none",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "none",
      },
      mutate: {
        errorPolicy: "none",
      },
    },
  });
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

// SSG時に取得した初期データが有るならキャッシュに入れ込む
export function initializeApollo(
  isMiddleware: boolean = false,
  initialState: NormalizedCacheObject | null = null
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(isMiddleware);

  if (initialState) {
    // ref: https://github.com/vercel/next.js/pull/17681#issuecomment-723476763
    const existingCache = _apolloClient.extract();

    const data = deepmerge(existingCache, initialState, {
      arrayMerge: (_target, source) => source,
    });
    _apolloClient.cache.restore(data);
  }
  if (typeof window === "undefined") return _apolloClient;
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(
  initialState: NormalizedCacheObject | null
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(
    () => initializeApollo(false, initialState),
    [initialState]
  );
  return store;
}
