import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

export type PortfolioCategory = {
  id?: string | undefined;
  title: string;
  works: PortfolioWork[];
};

export type PortfolioWork = PortfolioWorkMangano | PortfolioWorkExternal;

type PortfolioWorkMangano = {
  type: "PortfolioWorkMangano";
  id: string; // 新規作成の場合manganoWorkIdが無いことがあるので、 識別子として workId が入る
  title: string;
  manganoWorkId: string;
};

type PortfolioWorkExternal = {
  type: "PortfolioWorkExternal";
  id: string;
  title: string;
};

type PortfolioCategoryEditor = {
  categoryCache: PortfolioCategory | undefined;
  setCategoryCache: Dispatch<SetStateAction<PortfolioCategory | undefined>>;
};

const PortfolioCategoryEditorContext = createContext<PortfolioCategoryEditor>({
  categoryCache: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCategoryCache: () => {},
});

export const PortfolioCategoryEditorProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [cache, setCache] = useState<PortfolioCategory>();
  const contextValue = useMemo<PortfolioCategoryEditor>(
    () => ({ categoryCache: cache, setCategoryCache: setCache }),
    [cache]
  );

  return (
    <PortfolioCategoryEditorContext.Provider value={contextValue}>
      {children}
    </PortfolioCategoryEditorContext.Provider>
  );
};

export function usePortfolioCategoryEditor(): PortfolioCategoryEditor {
  return useContext(PortfolioCategoryEditorContext);
}
